.toggle-group {
  --toggle-group-color: var(--purple);
  --toggle-group-color-rgb: var(--purple-rgb);
  --toggle-group--radius: 4px;

  display: inline-flex;
  background-color: var(--mauve6);
  border-radius: var(--toggle-group--radius);
  box-shadow: 0 2px 10px var(--blackA7);
}
.toggle-group--green {
  --toggle-group-color: var(--green);
  --toggle-group-color-rgb: var(--green-rgb);
}

.toggle-group--skyblue {
  --toggle-group-color: var(--skyblue);
  --toggle-group-color-rgb: var(--skyblue-rgb);
}

.toggle-group--full-width {
  --toggle-group--radius: 8px;

  display: flex;
  width: 100%;
}

.toggle-group--item {
  padding: 0.55rem 0.75rem;
  color: var(--toggle-group-color);
  background-color: transparent;
  border: 2px solid var(--toggle-group-color);
  display: flex;
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  align-items: center;
  justify-content: center;
}
.toggle-group--item:first-child {
  border-top-left-radius: var(--toggle-group--radius);
  border-bottom-left-radius: var(--toggle-group--radius);
}
.toggle-group--item:not(:first-child) {
  margin-left: -2px;
}
.toggle-group--item:last-child {
  border-top-right-radius: var(--toggle-group--radius);
  border-bottom-right-radius: var(--toggle-group--radius);
}
.toggle-group--item:hover {
  background-color: rgba(var(--toggle-group-color-rgb), 0.15);
}
.toggle-group--item[data-state="on"] {
  background-color: var(--toggle-group-color);
  color: var(--white);
}
.toggle-group--item:focus {
  position: relative;
  box-shadow: 0 0 0 2px rgba(var(--toggle-group-color-rgb), 0.75);
}

.toggle-group--full-width .toggle-group--item {
  width: 100%;
}
