.footer {
  padding: 40px;
  width: 100%;
  height: 100%;
}

.footer--default {
  background: linear-gradient(
    -90deg,
    #2eebdb,
    #5487f3,
    #1aa7db,
    #1adbc1,
    #1ae0a6,
    #1accaf,
    #00c56b,
    #1ae6cc
  );
  background-size: 1200% 100%;
  animation: hero-gradient 30s ease infinite;
}

/* student */
.footer--me {
  background: var(--green);
}

/* tutor */
.footer--org-tutor {
  background: var(--orange);
}

/* organization / tadpole admin */
.footer--parent {
  background: var(--dark-blue);
}

/* organization / tadpole admin */
.footer--org-admin,
.footer--tadpole-admin {
  background: var(--purple);
}

.footer--logo {
  display: block;
  max-width: 200px;
  width: 100%;
}

.footer--logo img {
  width: 100%;
}

.footer ul {
  flex-direction: row;
  list-style: none;
}

.footer li {
  padding-bottom: 10px;
}

.footer p {
  color: var(--white);
}

.footer img {
  max-width: 224px;
  width: 100%;
  position: relative;
}

.footer--link {
  color: var(--white);
  border-bottom: 1px solid;
  display: inline-block;
}

.footer--link:hover,
.footer--link:focus,
.footer--link:focus-visible {
  color: var(--white);
  font-weight: bold;
  border-bottom: 3px solid;
}

.footer .container {
  display: flex;
  margin: 0 auto;
}

@keyframes hero-gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
