:root {
  --color-rgb: var(--medium-gray-rgb);
}

.tag {
  display: inline-block;
  padding: 0.6rem 1rem;
  border-radius: 8px;
  font-size: 14px;
  background-color: rgb(var(--color-rgb));
  color: rgb(var(--black-rgb));
}

.tag.gold {
  --color-rgb: var(--golden-rgb);
  color: var(--white);
}

.tag.purple {
  --color-rgb: var(--purple-rgb);
  color: var(--white);
}

.tag.green {
  --color-rgb: var(--green-rgb);
  color: var(--white);
}

.tag.blue {
  --color-rgb: var(--dark-blue-rgb);
  color: var(--white);
}

.tag.dark-gray {
  --color-rgb: var(--medium-gray-rgb);
  color: var(--black);
}

.tag.mini {
  padding: 0.3rem 0.65rem;
  border-radius: 8px;
  font-size: 11px;
  line-height: 11px;
}

.tag.small {
  padding: 0.4rem 1rem;
  border-radius: 8px;
  font-size: 0.75em;
}

.tag.medium {
  padding: 0.45rem 1rem;
  border-radius: 8px;
  font-size: 0.875em;
  font-weight: bold;
}

.tag.large {
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  font-size: 15px;
}

.tag.hollow {
  color: var(--color-rgb) !important;
  background-color: transparent !important;
}

.tag.translucent {
  color: rgb(var(--color-rgb)) !important;
  border: 2px solid rgb(var(--color-rgb)) !important;
  background-color: rgba(var(--color-rgb), 0.2) !important;
}

.tag.narrow {
  padding: 0.2rem 1rem;
  border-radius: 12px;
  font-weight: bold;
}

.tag.square {
  padding: 0.25rem 0.25rem;
  border-radius: 5px;
  width: 2rem;
  height: 2rem;
  text-align: center;
}
