.avatar--root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  overflow: hidden;
  user-select: none;
  border-radius: 100%;
  border: 1px solid black;
  color: rgba(var(--green-rgb), 0.1);
  background-color: white;
}

.avatar--root.purple {
  color: rgba(var(--indigo-rgb), 0.1);
}

.avatar--root.orange {
  color: rgba(var(--orange-rgb), 0.1);
}

.avatar--default {
  width: 45px;
  height: 45px;
}

.avatar--large {
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
}

.avatar--small {
  width: 2.5rem;
  height: 2.5rem;
}

.avatar--image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}

.avatar--fallback {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: var(--green);
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
}

.avatar--root.purple .avatar--fallback {
  color: var(--indigo);
}

.avatar--inverted {
  color: black;
  border: none;
  border: 2px solid var(--green);
  border-radius: 100%;
}

.avatar--inverted.purple {
  border: 2px solid var(--indigo);
  border-radius: 100%;
}

.avatar--root.orange .solid {
  color: var(--white);
  background-color: var(--orange);
}

.avatar--large .avatar--fallback {
  font-size: 48px;
}
