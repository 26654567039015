@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: var(--green);
  --primary-rgb: var(--green-rgb);
  --secondary: #606c80;
  --darkred: #953f3b;
  --bg: #fdfdfe;
  --white: #ffffff;
  --black: #000000;
  --black-rgb: 0, 0, 0;
  --grey: #b2b6c5;
  --purple: #534888;
  --error: #ee787a;
  --yellow: #dbb75b;
  --golden: #e6a25b;
  --golden-rgb: 230, 162, 91;
  --lightgreen: #66d899;
  --orange-rgb: 237, 137, 65;
  --orange: rgb(var(--orange-rgb));

  --green: rgb(var(--green-rgb));
  --green-rgb: 0, 197, 107;
  --purple: #90a9fb;
  --purple-rgb: 144, 169, 251;
  --indigo: #5d7ef2;
  --indigo-rgb: 93, 126, 242;
  --pink: #ffc8c6;
  --pink-rgb: 255, 209, 207;
  --navy: #458abb;
  --navy-rgb: 69, 138, 187;
  --dark-blue: #62acff;
  --dark-blue-rgb: 98, 172, 255;
  --red: #d86560;
  --red-rgb: 216, 101, 96;
  --blue: #7bfbf3;
  --blue-rgb: 144, 251, 245;
  --slate-blue: #abd5de;
  --slate-blue-rgb: 171, 213, 222;
  --yellow: #fbdf7b;
  --yellow-rgb: 251, 228, 144;
  --gray: #f4f5f6;
  --gray-rgb: 244, 245, 246;
  --medium-gray: #d9d9d9;
  --medium-gray-rgb: 217, 217, 217;
  --dark-gray: #606c80;
  --dark-gray-rgb: 96, 108, 128;
  --skyblue: #3db7e3;
  --skyblue-rgb: 61, 183, 227;

  --font-sans-serif: "Inter", sans-serif;

  --font-size-sm: clamp(0.8rem, 0.17vw + 0.76rem, 0.89rem);
  --font-size-base: clamp(1rem, 0.34vw + 0.91rem, 1.19rem);
  --font-size-md: clamp(1.25rem, 0.61vw + 1.1rem, 1.58rem);
  --font-size-lg: clamp(1.56rem, 1vw + 1.31rem, 2.11rem);
  --font-size-xl: clamp(1.95rem, 1.56vw + 1.56rem, 2.81rem);
  --font-size-xxl: clamp(2.44rem, 2.38vw + 1.85rem, 3.75rem);
  --font-size-xxxl: clamp(3.05rem, 3.54vw + 2.17rem, 5rem);
}

.text-green {
  color: var(--green);
}

.text-purple {
  color: var(--purple);
}

.text-orange {
  color: var(--orange);
}

.text_grey {
  color: var(--secondary);
}

.text_skyblue {
  color: var(--skyblue);
}

.text_signup {
  color: #8f92a1;
}

textarea {
  resize: none;
}

@layer components {
  .input-label {
    @apply block text-sm font-medium leading-6 text-neutral-800;
  }

  .input {
    @apply w-full leading-7 bg-white border border-gray-300 border-solid rounded-md shadow-sm form-input hover:border-gray-400 placeholder:text-gray-400 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 sm:text-sm sm:leading-6;
  }
}

body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  overflow: scroll;
  overflow-x: hidden;
  font-family: var(--font-sans-serif);
}

.wrapper {
  padding-top: 85px;
}

:disabled {
  cursor: not-allowed !important;
}

img {
  width: 100%;
}

.headline-9 {
  font-family: var(--font-sans-serif);
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 30px;
}

.primary-text {
  font-family: var(--font-sans-serif);
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}

.primsecond-text {
  font-family: var(--font-sans-serif);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 124.99%;
}

.secondary-text {
  font-family: var(--font-sans-serif);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

small {
  font-size: 0.85em;
}

.small-text {
  font-family: var(--font-sans-serif);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.btn__back {
  display: flex;
  align-items: center;
  background: transparent;
  text-decoration: none;
  color: #262626;
  margin-bottom: 20px;
}

.btn__back:hover {
  color: #262626;
}

.btn__back svg {
  transform: rotate(90deg);
  margin-right: 5px;
}

.reg__error_mess {
  color: #d86560;
}

.reg__input_icon {
  position: absolute;
  max-width: 22px;
  width: 100%;
  top: 26px;
  right: 22px;
}

.upcoming__block {
  padding: 30px;
  background: #f8f8f8;
  border-radius: 12px;
  margin-top: 32px;
}

.rating {
  position: relative;
  max-width: 150px;
  background: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.3em;
  padding: 10px 0 0;
  overflow: hidden;
}

.rating__result {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-10px) translateX(-5px);
  z-index: -9;
  font: 3em Arial, Helvetica, sans-serif;
  color: #ebebeb8e;
  pointer-events: none;
}

.rating__star {
  font-size: 1.3em;
  cursor: pointer;
  color: #dabd18b2;
  transition: filter linear 0.3s;
}

.rating__star:hover {
  filter: drop-shadow(1px 1px 4px gold);
}

label > input[type="radio"] {
  display: none;
  cursor: pointer;
}

label > input[type="radio"] + *::before {
  content: "";
  display: inline-block;
  vertical-align: bottom;
  width: 1rem;
  height: 1rem;
  margin-right: 0.3rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  border-color: gray;
  cursor: pointer;
}

label > input[type="radio"]:checked + * {
  color: teal;
}

label > input[type="radio"]:checked + *::before {
  background: radial-gradient(teal 0%, teal 40%, transparent 50%, transparent);
  border-color: teal;
}

/* basic layout */

label > input[type="radio"] + * {
  display: inline-block;
  padding: 15px 10px;
}

.h3 {
  font-family: var(--font-sans-serif);
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
}

.profile-img {
  height: 120px;
  width: 120px;
  object-fit: cover;
  border-radius: 99%;
}

.subheading {
  max-width: 690px;
  margin-top: 1rem;
  font-size: 18px;
  line-height: 28px;
}

textarea#styled {
  max-width: 436px;
  height: 200px;
  width: 100%;
  background: #f8f8f8;
  border-radius: 8px;
  padding: 18px;
  resize: vertical;
}

.signup_text {
  font-family: var(--font-sans-serif);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #8f92a1;
}

.signup__back {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  color: #262626;
  cursor: pointer;
  text-decoration: none;
}

.signup__back:hover {
  color: #262626;
}

.signup__back span {
  margin-left: 10px;
}

.signup__back svg {
  transform: rotate(90deg);
}

.signup__back svg path {
  stroke: #262626;
}

.signup__nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.signup__nav_circle {
  max-width: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #8d8d8d;
  opacity: 0.2;
}

.signup__nav .active {
  max-width: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  color: #52b09e;
  border: 2px solid #52b09e;
  box-sizing: border-box;
  opacity: 2;
}

.signup__nav .active_bg {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background: #52b09e;
  opacity: 2;
  box-sizing: border-box;
}

.signup__nav_2 .signup__nav_line {
  width: 315px;
  height: 4px;
  background: #8d8d8d;
  opacity: 0.2;
  border-radius: 5px;
}

.signup__nav_3 .signup__nav_line {
  width: 135px;
  height: 4px;
  background: #8d8d8d;
  opacity: 0.2;
  border-radius: 5px;
}

.signup__nav_5 .signup__nav_line {
  width: 50px;
  height: 4px;
  background: #8d8d8d;
  opacity: 0.2;
  border-radius: 5px;
}

.signup__nav_line {
  width: 75px;
  height: 4px;
  background: #8d8d8d;
  opacity: 0.2;
  border-radius: 5px;
}

.signup__nav .line_active {
  background: #52b09e;
  opacity: 2;
}

.signup__nav .line_active-yellow {
  background: #dbb75b;
  opacity: 2;
  width: 50px;
}

@media (max-width: 400px) {
  .signup {
    padding-top: 50px;
  }

  .signup__nav_line {
    width: 53px;
  }
}

.invite_block {
  padding: 20px 26px;
  background: linear-gradient(to left, #fdf5f5 99.5%, #60d87e 0%);
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
}

.tutor__profile_block {
  padding: 20px 26px;
  background: linear-gradient(to left, #fdf5f5 99.5%, #d86560 0%);
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
}

.tutor__profile_block-text span {
  font-family: var(--font-sans-serif);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 124.99%;
  color: #262626;
}

.tutor__profile_block-text p {
  font-family: var(--font-sans-serif);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 124.99%;
  color: #606c80;
}

.dashboard_hr {
  width: 100%;
  height: 1px;
  background: #e8e8e8;
  margin-top: 30px;
  margin-bottom: 32px;
}

.footer__text {
  color: #606c80;
}

.footer__text a {
  margin-left: 5px;
  margin-right: 5px;
  color: #52b09e;
}

.footer__text a:hover {
  color: #52b09e;
}

strong,
b,
.important-text {
  font-weight: bold;
}

em {
  font-style: italic;
}

.page--header {
  padding: 2rem 0;
}

.page--title {
  font-family: var(--font-sans-serif);
  font-weight: 700;
  font-size: 42px;
  padding-bottom: 1.15rem;
}

.section--title {
  font-size: 32px;
  font-weight: 600;
  line-height: 32px;
}

.form--radio {
  display: none;
  cursor: pointer;
}

.form--radio + *::before {
  content: "";
  display: inline-block;
  vertical-align: bottom;
  width: 1rem;
  height: 1rem;
  margin-right: 0.3rem;
  border-radius: 50%;
  border: 1px solid var(--grey);
  cursor: pointer;
}

.form--radio:checked + * {
  color: var(--green);
}

.form--radio:checked + *::before {
  background: radial-gradient(
    var(--green) 0%,
    var(--green) 40%,
    transparent 50%,
    transparent
  );
  border-color: var(--green);
}

.form--radio:checked:disabled + * {
  color: var(--grey);
}

.form--radio:checked:disabled + *::before {
  background: radial-gradient(
    var(--grey) 0%,
    var(--grey) 40%,
    transparent 50%,
    transparent
  );
  border-color: var(--grey);
}

.form--radio + * {
  display: inline-block;
  padding: 15px 10px;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

.spinner-border-square {
  width: 0.925rem;
  height: 0.925rem;
  border-width: 0.15em;
}

.rdp-day_selected {
  background-color: var(--rdp-accent-color) !important;
}

.rdp-caption_label {
  z-index: 0 !important;
}

.react-tel-input .special-label {
  display: none;
}

.react-tel-input .flag-dropdown {
  background-image: none; /* Remove the background image */
  padding-left: 0; /* Adjust padding if necessary */
}

.react-tel-input .flag-dropdown .selected-flag {
  display: none; /* Hide the selected flag */
}
