.bubble-shape {
  position: relative;
  width: 300px;
  height: 338px;
  background-color: white;
  border-radius: 6px;
  border: 1px solid #000;
  background: #fff;
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.25);
  z-index: 10;
}

.outside-triangle {
  position: absolute;
  top: -11px;
  left: 90%;
}

.hour-input:focus {
  outline: 0;
}
