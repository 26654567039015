.top-third {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggle-container {
  display: flex;
  border: 1px solid #9c27b0;
  border-radius: 9999px;
  overflow: hidden;
  align-items: center;
  justify-content: flex-start;
  transform-origin: left center;
}

.toggle-button {
  background: #ffffff;
  color: #000000;
  padding: 0.25rem 0.5rem;
  border: none;
  cursor: pointer;
  font-size: 0.75rem;
  outline: none;
  transition: background-color 0.3s, color 0.3s;
}

.toggle-button.active {
  background: #d8bfee;
  color: #ffffff;
}

.toggle-button:not(.active):hover {
  background: #f5f5f5;
}
