@media (max-width: 410px) {
  .parent-list-container {
    flex-direction: column !important;
    gap: 0.5rem;
    align-items: start !important;
  }

  .parent-list-buttons {
    width: 100%;
  }
}
