.clear-availability--actions {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

@media (min-width: 960px) {
  .clear-availability--actions {
    flex-direction: row;
  }

  .clear-availability--actions .button {
    width: 100%;
  }
}
