.waiting-dialog-content {
  background-color: rgb(24, 143, 182) !important;
  height: 50vh;
}

.waiting-dialog-text {
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  margin-top: 40px;
}
