.org-data-key-dialog {
  --dialog-background: white;
  background-color: var(--dialog-background);
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  max-width: 750px;
  min-height: 60vh;
  max-height: 85vh;
  padding: 25px;
  z-index: 50;
  overflow-y: auto;
}

.new-org-data-key--dialog-header {
  margin-bottom: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-org-data-key--title {
  font-weight: bold;
  font-size: 1.15em;
}

.new-org-data-key--dialog-header p {
  margin-top: 0.5rem;
  font-size: 14px;
  line-height: 1.4em;
}

.new-org-data-key--status {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
}
