.StatisticsContainer {
  font-family: "Montserrat", sans-serif;
}

.StatisticsBox {
  font-size: 12px;
  font-weight: 700;
  margin-top: 10px;
}

.StatisticsBox div {
  font-size: 20px;
  margin-top: 10px;
}

.StatisticsButton {
  color: white;
  background-color: #3db7e3;
  border-radius: 13px;
  height: 35px;
  width: 110px;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  margin-left: 40px;
}

.dateContainer input {
  min-width: 140px;
  height: 30px;
  font-size: 14px;
  user-select: none;
}
