.button-container {
  display: flex;
  flex-direction: row;
}

.payments-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #3db7e3;
  max-width: 100px;
  height: 100px;
  border-radius: 15px;
  color: #3db7e3;
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  padding: 10px 5px 5px;
}

.payments-button:hover {
  background-color: #ebf8fd;
}
