.list--actions {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.list--actions > * {
  width: 100%;
  width: calc(50% - 0.25rem);
  align-self: stretch;
}

.list--search-input {
  border-radius: 8px !important;
  border: 1px solid var(--purple) !important;
  outline: 1px solid transparent !important;
  background-color: rgba(var(--purple-rgb), 0.1) !important;
  transition: border-color 0.15s ease, outline 0.15s ease, background 0.15s ease !important;
}

.list--search-input.orange {
  border-color: var(--orange) !important;
  background-color: rgba(var(--orange-rgb), 0.1) !important;
}

.list--search-input:hover {
  background-color: transparent;
}

.list--search-input:focus {
  outline: 1px solid var(--purple);
}

.inactive-student-indicator {
  color: var(--blue);
}

@media (min-width: 960px) {
  .list--search-input {
    width: 150px;
    width: min(150px, 100%);
    justify-content: flex-end;
  }

  .list--actions > * {
    width: auto;
    flex-shrink: 0;
  }
}

@media (max-width: 410px) {
  .student-list-container {
    flex-direction: column !important;
    gap: 0.5rem;
    align-items: start !important;
  }

  .student-list-buttons {
    width: 100%;
  }
}
