.transactions-container {
  width: 100%;
}

.table {
  width: 100%;
  font-family: "Montserrat", sans-serif;
}

.tableHead {
  text-decoration: underline;
  font-size: 19px;
  color: #b8b8b8;
  text-align: left;
}

.centeredTableData {
  text-align: center;
}

.centeredTableData button {
  font-weight: bold;
  text-decoration: none;
  background-color: white;
  border: none;
}

.tableRow {
  font-size: 15px;
  color: black;
  text-align: left;
  height: 40px;
}

.purple-mark {
  color: var(--purple);
  font-size: 24px;
}

.green-mark {
  color: var(--green);
  font-size: 24px;
}

.red-mark {
  color: var(--red);
  font-size: 24px;
}

.gold-mark {
  color: var(--gold);
  font-size: 24px;
}
