.account-notes--title {
  font-weight: bold;
  font-size: 1.15em;
  margin-bottom: 0.75rem;
}

.account-note {
  padding: 0.75rem 0.75rem 0.75rem 1rem;
  border-left: 2px solid transparent;
  border-radius: 0 8px 8px 0;
}

.account-note.purple {
  border-color: var(--purple);
  background-color: rgba(var(--purple-rgb), 0.1);
}

.account-note.orange {
  border-color: var(--orange);
  background-color: rgba(var(--orange-rgb), 0.1);
}

.account-note.green {
  border-color: var(--green);
  background-color: rgba(var(--green-rgb), 0.1);
}

.account-note.red {
  border-color: var(--red);
  background-color: rgba(var(--red-rgb), 0.1);
}

.account-note.blue {
  border-color: var(--blue);
  background-color: rgba(var(--blue-rgb), 0.1);
}

.account-note.skyblue {
  border-color: var(--skyblue);
  background-color: rgba(var(--skyblue-rgb), 0.1);
}

.account-note:not(:last-of-type) {
  margin-bottom: 1rem;
}

.account-notes--meta {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
}

.account-notes--actions {
  display: flex;
  gap: 0.25rem;
}

.account-notes--timestamp {
  display: block;
  font-size: 0.85em;
}

.account-notes--note {
  font-size: 0.95em;
  white-space: pre-wrap;
}
