.status-block--title {
  font-family: var(--font-sans-serif);
  font-weight: 700;
  font-size: 60px;
  padding-bottom: 1.15rem;
}

.status-block--inline .status-block--title {
  padding-top: 0.5rem;
  padding-bottom: 0.35rem;
}
