.rdp {
  background: white !important;
  border-radius: 5px;
  box-shadow: 6px 6px 6px 6px rgba(0, 0, 0, 0.25);
  padding: 10px 5px 10px 5px;
  --rdp-cell-size: 35px !important;
  --rdp-accent-color: var(--skyblue);
  z-index: 10;
}

.rdp-button {
  margin: 1px !important;
}

.rdp-dropdown_year,
.rdp-dropdown_month {
  color: rgba(0, 0, 0, 0.6) !important;
}

.rdp-head_row {
  color: rgba(0, 0, 0, 0.6);
}

.rdp-cell:hover {
  background-color: #e7edff;
  border-radius: 50%;
}

.rdp-caption_label {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.rdp-day {
  font-size: 0.857rem;
  line-height: 1.25rem;
}
