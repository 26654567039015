.visualization-container {
  width: 100%;
  padding: 1rem;
}

.visualization-selector {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  gap: 1rem;
}

@media (min-width: 640px) {
  .visualization-selector {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.visualization-selector .flex-1 {
  max-width: 400px;
}

.visualization-columns {
  display: flex;
  gap: 1px;
  overflow-x: auto;
  padding: 1rem 0;
  border-bottom: 1px solid #e5e7eb;
}

.visualization-column {
  min-width: 200px;
  max-width: 200px;
  border: 1px dotted #e5e7eb;
  border-right: none;
  display: flex;
  flex-direction: column;
}

.visualization-column:last-child {
  border-right: 1px dotted #e5e7eb;
}

.column-header {
  width: 100%;
}

.column-header h3 {
  margin: 0;
  text-align: center;
  border-radius: 0.375rem 0.375rem 0 0;
}

.column-content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-height: 300px;
}

/* Custom scrollbar styles */
.visualization-columns::-webkit-scrollbar {
  height: 8px;
}

.visualization-columns::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.visualization-columns::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.visualization-columns::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.visualization-dialog-content {
  --dialog-background: white;
  background-color: var(--dialog-background);
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 450px;
  min-height: 10vh;
  max-height: 85vh;
  padding: 25px;
  z-index: 50;
  overflow-y: auto;
}

.visualization-column-dialog-content {
  --dialog-background: white;
  background-color: var(--dialog-background);
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  max-width: 90vw;
  height: fit-content;
  max-height: 85vh;
  padding: 25px;
  z-index: 50;
  overflow-y: auto;
}

.column-actions-dialog-content {
  --dialog-background: white;
  background-color: var(--dialog-background);
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  height: fit-content;
  padding: 25px;
  z-index: 50;
  overflow-y: auto;
}
.column-admin-info-dialog-content {
  --dialog-background: white;
  background-color: var(--dialog-background);
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%) scale(0.9);
  min-width: 800px;
  height: fit-content;
  max-height: 95vh;
  padding: 25px;
  z-index: 50;
  overflow-y: auto;
}
