.org-data--dialog-header {
  margin-bottom: 0.75rem;
}

.org-data--title {
  font-weight: bold;
  font-size: 1.15em;
}

.org-data--dialog-header p {
  margin-top: 0.25rem;
  font-size: 14px;
}

.org-data--pair {
  padding: 0.6rem 0.7rem;
  border-left: 2px solid var(--org-note-color);
  border-radius: 0 8px 8px 0;
  background-color: rgba(var(--org-note-color-rgb), 0.1);
}

.org-data--pair.purple {
  --org-note-color: var(--purple);
  --org-note-color-rgb: var(--purple-rgb);
}

.org-data--pair.orange {
  --org-note-color: var(--orange);
  --org-note-color-rgb: var(--orange-rgb);
}

.org-data--pair.green {
  --org-note-color: var(--green);
  --org-note-color-rgb: var(--green-rgb);
}

.org-data--pair.skyblue {
  --org-note-color: var(--skyblue);
  --org-note-color-rgb: var(--skyblue-rgb);
}

.org-data--pair.dark-blue {
  --org-note-color: var(--dark-blue);
  --org-note-color-rgb: var(--dark-blue-rgb);
}

.org-data--pair:not(:last-of-type) {
  margin-bottom: 0.5rem;
}

.org-data--status {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
}
