.rdp {
  background: white !important;
  border-radius: 5px;
  box-shadow: 4px 4px 8px #ebebeb;
  padding: 10px 5px 10px 5px;
  --rdp-cell-size: 35px !important;
  z-index: 10;
}

.rdp-button {
  margin: 1px !important;
}

.rdp-dropdown_year,
.rdp-dropdown_month {
  color: rgba(0, 0, 0, 0.6) !important;
}

.rdp-head_row {
  color: rgba(0, 0, 0, 0.6);
}

.rdp-cell:hover {
  background-color: #e7edff;
  border-radius: 50%;
}

.rdp-caption_label {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.rdp-day {
  font-size: 0.857rem;
  line-height: 1.25rem;
}

.availability-daypicker-red {
  background-color: #e89e99 !important;
}

.availability-daypicker-red:hover {
  background-color: rgba(232, 158, 153, 0.5) !important;
}

.availability-daypicker-yellow {
  background-color: #e7dc98 !important;
}

.availability-daypicker-yellow:hover {
  background-color: rgba(231, 220, 152, 0.5) !important;
}

.availability-daypicker-green {
  background-color: #98e8c4 !important;
}

.availability-daypicker-darkgreen {
  background-color: #3eb24a !important;
}

.availability-daypicker-darkgreen:hover {
  background-color: rgba(62, 178, 74, 0.5) !important;
}

.availability-daypicker-gray {
  background-color: #ebebeb !important;
}

.availability-daypicker-gray:hover {
  background-color: rgba(235, 235, 235, 0.5) !important;
}

.availability-daypicker-green:hover {
  background-color: rgba(152, 232, 196, 0.5) !important;
}

.availability-daypicker-selected:not([disabled]) {
  font-weight: bold;
  border: 2px solid var(--indigo);
}

.availability-daypicker-selected:hover:not([disabled]) {
  border: 2px solid var(--indigo);
}

.availability-daypicker-today {
  --rdp-outline-selected: 2px solid var(--indigo);
  font-weight: bold;
}
