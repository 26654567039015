.meeting-dialog--title {
  font-weight: bold;
  font-size: 1.15em;
  margin-bottom: 0.75rem;
}

.meeting-dialog-new--title {
  font-weight: bold;
  font-size: 1.75rem;
  margin-bottom: 0.75rem;
}

.meeting-dialog--subject {
  margin-bottom: 1.5rem;
}

.meeting-dialog--actions {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.meeting-dialog--attendee {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.meeting-dialog--attendee:not(:last-of-type) {
  margin-bottom: 0.75rem;
}

.meeting-dialog--attendee-details {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  gap: 0.75rem;
  justify-content: space-between;
  align-items: center;
}

.meeting-dialog-new--attendee-details {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
}

.meeting-dialog--attendee-actions {
  display: flex;
  gap: 0.5rem;
}

.wide {
  max-width: 1200px;
}

.medium-wide {
  max-width: 968px;
}

.medium {
  max-width: 680px;
}

.meeting-dialog--section-header {
  font-family: "Futura PT", sans-serif;
}
