.page--tutor-availability {
  padding-bottom: 3rem;
}

.schedule {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid #f8f8f8;
  border-radius: 18px;
  overflow: hidden;
  overflow-x: auto;
}

.schedule--day {
  width: 100%;
  background-color: #f8f8f8;
}

.date {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 0.75rem 0.5rem;
  font-size: 1.1em;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.date--actions {
  display: flex;
  flex-direction: row;
  gap: 0.35rem;
}

.schedule--day:not(:last-of-type) .availabilities {
  border-right: 2px solid #f8f8f8;
}

.button--manage-day {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  padding: 0;
}

.availabilities {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 1rem;
  background-color: var(--white);
}

.availability--none {
  background-color: var(--orange);
  height: 25px;
  padding: 0.3rem 0.65rem;
  color: var(--white);
  border-radius: 8px;
  font-size: 0.8em;
}

.availability--free {
  background-color: var(--green);
  height: 25px;
  padding: 0.3rem 0.65rem;
  color: var(--white);
  border-radius: 8px;
  font-size: 0.8em;
}

.availability--busy {
  display: none;
  background-color: var(--white);
  height: 25px;
  padding: 0.3rem 0.65rem;
  color: var(--red);
  border-radius: 8px;
  font-size: 0.8em;
}

.timezone {
  margin-top: 1rem;
}

@media (min-width: 960px) {
  .schedule {
    flex-direction: row;
    margin-left: -1rem;
    margin-right: -1rem;
  }
}
