.transaction-settings-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

select.rounded-lg {
  outline: none;
  font-size: 0.9rem;
}

select.rounded-lg:focus {
  border-color: #a59fea;
  box-shadow: 0 0 0 1px #a59fea;
}
