.invite-dialog--title {
  font-weight: bold;
  font-size: 1.15em;
  margin-bottom: 0.75rem;
}

.input {
  width: 100%;
  padding: 1.15rem 1.35rem;
  background-color: #f8f8f8;
  border-radius: 8px;
  outline: none;
  border: none;
  margin-top: 0.5rem;
}

.invite-dialog--textarea {
  width: 100%;
  height: 100px;
  padding: 1.15rem 1.35rem;
  background-color: #f8f8f8;
  border-radius: 8px;
  outline: none;
  border: none;
  margin-top: 0.5rem;
}
