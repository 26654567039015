.profile__header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.75rem;
}

@media (max-width: 500px) {
  .profile__header .headline-9 {
    font-size: 20px;
  }
}

@media (min-width: 769px) {
  .profile__header {
    flex-direction: row;
    align-items: center;
  }
}

.profile__edit {
  padding: 12px 20px;
  background: rgb(82, 176, 158);
  border-radius: 10px;
  color: rgb(255, 255, 255) !important;
}

.profile-edit--actions {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
}

.profile-edit--actions.organization {
  --profile-link-rgb: var(--purple-rgb);
}

.profile-edit--actions.tutor {
  --profile-link-rgb: var(--orange-rgb);
}

@media (min-width: 769px) {
  .profile-edit--actions {
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }
}

.profile-link--wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  border: 1px solid rgb(var(--profile-link-rgb));
  overflow: hidden;
}

.profile-link--wrapper .link {
  padding: 0.325rem 0.75rem;
}

.profile-link--wrapper .icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--white);
  height: 100%;
  padding: 0.5rem 0.65rem;
  background-color: rgba(var(--profile-link-rgb), 0.15);
  transition: all 0.15s ease;
}

.profile-link--wrapper .icon:hover {
  background-color: rgb(var(--profile-link-rgb));
  color: var(--white);
}

.profile-link--wrapper .icon svg {
  display: block;
  margin: 0 auto;
  width: 18px;
  height: auto;
  aspect-ratio: 1/1;
}

@media (min-width: 960px) {
  .profile-edit--actions {
    flex-direction: row;
  }
}
