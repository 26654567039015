.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownContent {
  border-top-right-radius: 0px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #f9f9f9;
  width: 220px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
}

.dropdownContent button {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background-color: #f9f9f9;
  border: none;
  width: 100%;
  text-align: left;
  border-radius: 8px;
}

.activeDropDown {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.dropdownContent button:hover {
  /* color: var(--green); */
  background-color: var(--gray);
}

.dropdownContent button:nth-last-child(1) {
  color: var(--green);
}

.profileBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.dropdown button {
  transition-duration: 0s;
}

.profileBtn span {
  margin-left: 5px;
}

.profileBtn svg {
  width: 20px;
  height: 20px;
}

.initials {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--lightgreen);
  border-radius: 50%;
  margin-left: 15px;
  margin-top: -5px;
  margin-bottom: -5px;
  padding: 13px;
  width: 20px;
  height: 20px;
  font-size: 0.6em;
  letter-spacing: 0.1em;
}

.settingsDialog {
  --dialog-background: white;
  background-color: var(--dialog-background);
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 450px;
  min-height: 10vh;
  max-height: 85vh;
  padding: 25px;
  z-index: 50;
  overflow-y: auto;
}

.tabsWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  width: 100%;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: center;
  color: #b1b1b1;
}

.tab {
  margin-right: 60px;
  cursor: pointer;
}

.active {
  color: #000000;
}

.infoItem {
  margin-bottom: 20px;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
}

.label {
  font-weight: 700;
  padding-bottom: 10px;
}

.textInputContainer {
  width: 235px;
}

.phoneInputContainer {
  width: 270px;
}

.radioContainer {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.radioContainer label {
  padding-left: 10px;
}

.title {
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85%;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.actionButton {
  margin-right: 20px;
  margin-left: 20px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownContent {
  border-top-right-radius: 0px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #f9f9f9;
  width: 220px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
}

.dropdownContent button {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background-color: #f9f9f9;
  border: none;
  width: 100%;
  text-align: left;
  border-radius: 8px;
}

.activeDropDown {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.dropdownContent button:hover {
  /* color: var(--green); */
  background-color: var(--gray);
}

.dropdownContent button:nth-last-child(1) {
  color: var(--green);
}

.profileBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.dropdown button {
  transition-duration: 0s;
}

.profileBtn span {
  margin-left: 5px;
}

.profileBtn svg {
  width: 20px;
  height: 20px;
}

.initials {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--lightgreen);
  border-radius: 50%;
  margin-left: 15px;
  margin-top: -5px;
  margin-bottom: -5px;
  padding: 13px;
  width: 20px;
  height: 20px;
  font-size: 0.6em;
  letter-spacing: 0.1em;
}
