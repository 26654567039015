.calendar--wrapper {
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas: "one" "two" "three" "four" "five" "six" "seven";
  gap: 0.75rem;
}

.calendar--day-header {
  font-weight: 700;
  height: 35px;
}

.calendar--day-current {
  border-radius: 100%;
  width: 35px;
  height: 35px;
}

.calendar--day-current.purple {
  background-color: var(--purple);
  color: var(--white);
}

.calendar--day-current.orange {
  background-color: var(--orange);
  color: var(--white);
}

.calendar--day-current.green {
  background-color: var(--green);
  color: var(--white);
}

.calendar--day-separator {
  font-weight: 300;
  color: var(--grey);
}

.calendar--day-events {
  display: flex;
  flex-direction: column;
  height: calc(100% - 1.25rem * 2);
  border-radius: 8px;
  background-color: var(--white);
}

.calendar--day-sidebar {
  display: flex;
  flex-direction: column;
  height: calc(100% - 1.25rem * 2);
  white-space: nowrap;
}

.calendar--day:nth-child(1) {
  grid-area: one;
}

.calendar--day:nth-child(2) {
  grid-area: two;
}

.calendar--day:nth-child(3) {
  grid-area: three;
}

.calendar--day:nth-child(4) {
  grid-area: four;
}

.calendar--day:nth-child(5) {
  grid-area: five;
}

.calendar--day:nth-child(6) {
  grid-area: six;
}

.calendar--day:nth-child(7) {
  grid-area: seven;
}

.calendar--day-no-events {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  height: 100%;
}

.calendar--day-no-events p {
  font-weight: 600;
  font-size: 0.95em;
}

.calendar--day-event {
  position: relative;
  padding: 0.25rem;
  border-radius: 8px;
  background-color: rgba(var(--calendar--day-event-color), 0.15) !important;
  border: 2px solid rgb(var(--calendar--day-event-color)) !important;
  border-top: 6px solid rgb(var(--calendar--day-event-color)) !important;
  height: 100%;
}

.calendar--day-event-block {
  position: relative;
  padding: 0.25rem;
  border-radius: 8px;
  background-color: rgba(var(--calendar--day-event-color)) !important;
  border: 2px solid rgb(var(--calendar--day-event-color)) !important;
  height: 100%;
}

.calendar--day-event-background {
  position: relative;
  background-color: transparent;
  min-height: 2rem;
}

.calendar--day-title {
  white-space: nowrap;
}

.calendar--day-time {
  margin-top: 0.25rem;
}

.calendar--time-separator {
  position: absolute;
  left: 0;
  right: 0;
}

.calendar--event-wrapper {
  position: absolute;
  left: 0;
  right: 0;
}

.calendar--day-event-blue {
  --calendar--day-event-color: var(--blue-rgb);
}

.calendar--day-event-purple {
  --calendar--day-event-color: var(--purple-rgb);
}

.calendar--day-event-yellow {
  --calendar--day-event-color: var(--yellow-rgb);
}

.calendar--day-event-pink {
  --calendar--day-event-color: var(--pink-rgb);
}

.calendar--day-event-slate-blue {
  --calendar--day-event-color: var(--slate-blue-rgb);
}

.calendar--day-event-navy {
  --calendar--day-event-color: var(--navy-rgb);
}

.calendar--day-event:not(b),
.calendar--day-event-block:not(b) {
  font-size: 0.7em;
}

@media (min-width: 960px) {
  .calendar--wrapper {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: "one one two two" "three three four four" "five five six seven";
  }
}

@media (min-width: 1025px) {
  .calendar--wrapper {
    grid-template-columns: repeat(7, 1fr);
    grid-template-areas: "one two three four five six seven";
  }

  .calendar--day-events {
    min-height: 105px;
  }
}

.meeting-container {
  /* The transform property will shrink the calendar */
  transform: scale(0.9);
  transform-origin: top left; /* or 0 0 */

  /* In some cases, you may need to adjust widths/heights so it doesn’t overflow */
  width: 111%;
  height: 111%;
  overflow: hidden;
}
