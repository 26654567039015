.profile-dialog.purple {
  --profile-dialog-color: var(--purple);
}

.profile--dialog.green {
  --profile-dialog-color: var(--green);
}

.profile-dialog.orange {
  --profile-dialog-color: var(--orange);
}

.profile-dialog--title {
  font-weight: bold;
  font-size: 1.15em;
  margin-bottom: 0.75rem;
}
