.page--parent-profile {
  min-height: calc(100vh - 100px);
  padding-bottom: 3rem;
}

.parent-profile--section {
  border-radius: 0.75rem;
  border: 2px solid #f8f8f8;
  width: 95%;
  height: 590px;

  @media (max-width: 639px) {
    width: 100%;
  }
}

.parent-profile--section-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  font-size: 24px;
  font-weight: 600;
  column-span: all;
  border-radius: 0.75rem;
  background-color: white;
  height: 15%;
}

.parent-profile--section-items {
  height: 85%;
  overflow: scroll;
}

.parent-profile--section-item {
  margin: 1rem 1.5rem;
  font-size: 1.05em;
  gap: 0.5rem;
}

.parent-detail--historical-meeting {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.parent-detail--historical-meeting--details {
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-sm);
}

.parent-detail--tutor {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  border-bottom: 1px solid #b8b8b8;
}

.parent-detail--parent-details {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  font-size: var(--font-size-sm);
}

.parent-detail--student {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.parent-detail--student-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.parent-phone-input-view {
  padding-left: 0 !important;
  height: 1.5rem;
  width: 100%;
  font-size: 14px !important; /* Correct font size */
  font-family: "Inter", sans-serif !important;
  outline: none !important;
  border: none !important;
}

.parent-phone-input-edit {
  padding-left: 0.25rem !important;
  height: 1.5rem;
  width: 58.333333%;
  border-radius: 0.375rem !important;
  border-width: 1px !important;
  border-color: black !important;
}

.parentDatePicker {
  height: 1.5rem !important;
  width: 58.333333% !important;
}
