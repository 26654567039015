@import "rsuite/dist/rsuite.css";

/* NOTE: The above import supports the date picker component. For whatever reason,
it turns the text on the landing page and other totally unrelated parts of
the site faded/gray. It also changes their font. BEWARE. */

.export-dialog {
  --dialog-background: white;
  background-color: var(--dialog-background);
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 750px;
  min-height: 35vh;
  max-height: 85vh;
  padding: 25px;
  z-index: 50;
  overflow-y: auto;
}
