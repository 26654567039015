.landing-page--hero {
  background: linear-gradient(
    -90deg,
    #2eebdb,
    #5487f3,
    #1aa7db,
    #1adbc1,
    #1ae0a6,
    #1accaf,
    #00c56b,
    #1ae6cc
  );
  width: 100%;
  background-size: 1200% 100%;
  animation: hero-gradient 30s ease infinite;
  clip-path: polygon(0 0, 100% 0%, 100% 70%, 0 100%);
  margin-top: -85px;
  padding-top: 85px;
}

.landing-page--hero-title {
  font-weight: 800;
  line-height: 1;
  line-height: 1.25em;
  word-spacing: 1px;
  padding-top: 100px;
}

@keyframes hero-gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.landing-page--text-gradient {
  background: linear-gradient(
    270deg,
    #1ad8a0 24.2%,
    #30cedc 50.22%,
    #5f78f3 77.36%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.features-carousel {
  width: 90%;
  margin: 5%;
  @media (min-width: 768px) {
    width: 120%;
    margin: 0 -10%;
  }
}

.request-demo-btn {
  margin-left: 4%;
  margin-top: -8rem;
}

.landing-title {
  width: 50%;
  @media (max-width: 768px) {
    margin-top: -5rem;
  }
}

.dashboard {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
