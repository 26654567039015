.success-dialog-content {
  background-color: var(--green) !important;
  height: 50vh;
}

.success-dialog-text {
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  margin-top: 40px;
}
