.tab-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  padding-bottom: 0.5rem;
  border-bottom: 4px solid transparent;
  transition: border-color 0.2s;
}

.tab-item.active-tab {
  border-bottom-color: var(--skyblue);
}

.tab-item:hover {
  border-bottom-color: var(--skyblue);
}

.tab-text {
  color: #b8b8b8;
}

.active-tab-text {
  color: black;
}
