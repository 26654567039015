.student-data--container {
  margin-top: 1rem;
  border: 2px solid #98acfc;
  border-radius: 0.75rem;
  padding: 0.5rem;
}

/* The header above the table */
.student-data--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #98acfc;
  padding: 0.75rem 1rem;
  border-radius: 0.75rem;
  margin-bottom: 1rem;
}

.custom-data--container {
  margin-top: 1rem;
  border: 2px solid #ac98fc;
  border-radius: 0.75rem;
  padding: 0.5rem;
}

/* The header above the table */
.custom-data--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ac98fc;
  padding: 0.75rem 1rem;
  border-radius: 0.75rem;
  margin-bottom: 1rem;
}

.student-data--field-actions {
  display: flex;
  gap: 0.5rem;
}

/* Ensure icons stay inline with text */
.student-data--field-actions button div,
.student-data--field-actions button span {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

/* The table itself: by default, 3 columns. */
.student-data--table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  background-color: #f8f8f8;
  padding: 0.75rem 1rem;
  border-radius: 0.75rem;
}

/* Cells: ensure a visible line and consistent padding */
.student-data--table td {
  border-bottom: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  padding: 1rem;
  vertical-align: middle;
}

/* Alternate row shading on row index = 1,3,5,... from the code */
.student-data--row-alt {
  background-color: #ffffff;
}

/* Keep name & actions horizontally aligned, even on small screens. */
.student-data--field-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.student-data--field-actions {
  display: flex;
  gap: 0.5rem;
}

@media (max-width: 600px) {
  .student-data--table td:nth-child(2),
  .student-data--table td:nth-child(3) {
    display: none;
  }
  .student-data--header {
    flex-direction: column;
    align-items: flex-start;
  }
  .student-data--field-actions {
    margin-top: 0.5rem;
  }

  .custom-data--header {
    flex-direction: column;
    align-items: flex-start;
  }
  .custom-data--field-actions {
    margin-top: 0.5rem;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  .student-data--table td:nth-child(3) {
    display: none;
  }
}
