.nav {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: var(--white);
  z-index: 10;
}

.nav__landing {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    -90deg,
    #2eebdb,
    #5487f3,
    #1aa7db,
    #1adbc1,
    #1ae0a6,
    #1accaf,
    #00c56b,
    #1ae6cc
  );
  background-size: 1200% 100%;
  animation: hero-gradient 30s ease infinite;
  z-index: 10;
}

@keyframes hero-gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.nav__body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  height: 85px;
}

.nav__logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.75rem;
}

.nav__logo img {
  width: 100%;
  max-width: 112px;
  height: auto;
}

.nav--org-name {
  display: block;
  border-left: 1px solid var(--black);
  padding-left: 0.75rem;
  height: 100%;
  color: var(--black);
}

.nav--org-name-landing {
  font-weight: 700;
}

.nav__burger {
  display: none;
  cursor: pointer;
}

.nav__burger.active:after {
  transform: rotate(-45deg);
  bottom: 9px;
}

.nav__burger.active:before {
  transform: rotate(45deg);
  top: 9px;
}

.nav__burger.active span {
  transform: scale(0);
}

.nav__menu ul {
  margin: 0;
}

.nav__list {
  display: flex;
  flex-direction: row;
  gap: 2.25rem;
  align-items: center;
}

.nav__list li {
  list-style: none;
  margin: 0;
}

.nav__link {
  display: inline-block;
  padding: 37px 0 28px 0;
  border-bottom: 3px solid transparent;
  color: var(--secondary);
  transition: color 0.15s ease, border-color 0.15s ease;
}

.nav__link_landing {
  color: var(--black);
  font-weight: 600;
}

.nav__link:hover {
  color: var(--black);
  border-color: var(--primary);
}

.nav__link.active {
  color: var(--black);
  border-color: var(--primary);
}

.nav__button {
  display: inline-block;
  font-size: 16px;
}

@media (max-width: 1092px) {
  .nav__body {
    padding: 0 1rem;
  }

  .nav__burger {
    display: block;
    position: relative;
    width: 30px;
    height: 20px;
    z-index: 3;
  }

  .nav__burger::before,
  .nav__burger::after {
    content: "";
    background-color: #606c80;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    transition: all 0.3 ease 0s;
  }

  .nav__burger::before {
    top: 0;
  }

  .nav__burger:after {
    bottom: 0;
  }

  .nav__burger span {
    position: absolute;
    background-color: #606c80;
    left: 0;
    width: 100%;
    height: 2px;
    top: 9px;
    transition: all 0.3 ease 0s;
  }

  .nav__menu {
    position: fixed;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100vh;
    padding: 140px 0 40px 0;
    border-radius: 10px;
    background: #ffffff;
    overflow-y: auto;
    opacity: 0;
  }

  .nav__menu.active {
    top: 0;
    opacity: 1;
    z-index: 100;
  }

  .nav__list {
    display: block;
    padding: 0 calc(var(--bs-gutter-x, 0.75rem) + 2rem);
    z-index: 5;
  }

  .nav__list li {
    margin: 0px 0px 20px 0px;
  }

  .nav__link {
    padding: 0;
  }
}
