.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 0.35rem;
  padding: 0.5rem 1.5rem;
  border-radius: 8px;
  border: 1px solid transparent;
  text-decoration: none;
  line-height: 20px;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  cursor: pointer;

  background-color: var(--button-color) !important;
  color: var(--button-color-contrast) !important;

  transition: all 0.15s ease-in;
}

.button--xs {
  padding: 0.1rem 0.75rem;
  border-radius: 6px;
  font-size: 11px;
}

.button--small {
  padding: 0.25rem 0.9rem;
  border-radius: 6px;
  font-size: 12px;
}

.button--small.button--square {
  padding: 0.35rem;
}

.button--large {
  padding: 0.8rem 2.25rem;
  font-size: 18px;
}

.button--large.button--square {
  padding: 0.9rem;
}

.button--block {
  width: 100%;
}

.button--circle {
  border-radius: 50%;
}

.button--square {
  padding: 0.6rem;
  line-height: 1;
}

.button--green {
  --button-color: var(--primary);
  --button-color-rgb: var(--primary-rgb);
  --button-color-contrast: var(--white);
}

.button--gray {
  --button-color: var(--gray);
  --button-color-rgb: var(--gray-rgb);
  --button-color-contrast: var(--black);
}

.button--orange {
  --button-color: var(--orange);
  --button-color-rgb: var(--orange-rgb);
  --button-color-contrast: var(--white);
}

.button--purple {
  --button-color: var(--purple);
  --button-color-rgb: var(--purple-rgb);
  --button-color-contrast: var(--white);
}

.button--red {
  --button-color: var(--red);
  --button-color-rgb: var(--red-rgb);
  --button-color-contrast: var(--white);
}

.button--blue {
  --button-color: var(--blue);
  --button-color-rgb: var(--blue-rgb);
  --button-color-contrast: var(--white);
}

.button--dark-blue {
  --button-color: var(--dark-blue);
  --button-color-rgb: var(--dark-blue-rgb);
  --button-color-contrast: var(--white);
}

.button--plum {
  --button-color: #dda0dd;
  --button-color-contrast: var(--white);
}

.button--skyblue {
  --button-color: var(--skyblue);
  --button-color-rgb: var(--skyblue-rgb);
  --button-color-contrast: var(--white);
}

.button--black {
  --button-color: var(--black);
  --button-color-rgb: var(--black-rgb);
  --button-color-contrast: var(--white);
}

.button--black.button--no-border {
  --button-color-contrast: var(--black);
}

.button--white {
  --button-color: var(--white);
  --button-color-rgb: var(--white-rgb);
  --button-color-contrast: var(--primary);
}

.button--float {
  background-color: transparent !important;
  color: var(--button-color) !important;
}

.button--hollow {
  background-color: transparent !important;
  border-color: var(--button-color) !important;
  color: var(--button-color) !important;
}

.button--no-border {
  background-color: transparent !important;
  border: none;
  color: var(--button-color) !important;
}

.button:hover:not(:disabled) {
  box-shadow: rgba(var(--button-color-rgb), 0.25) 0 0 10px 5px;
  color: var(--button-color-contrast) !important;
}

.button--gray:hover:not(:disabled) {
  border-color: var(--primary) !important;
}

.button--float:hover:not(:disabled) {
  background-color: var(--button-color) !important;
  color: var(--white) !important;
}

.button--hollow:hover:not(:disabled) {
  background-color: var(--button-color) !important;
  color: var(--white) !important;
}

.button--chip {
  padding: 0.5rem 1rem;
  border-radius: 50px;
  border: 2px solid transparent;
  transition: all 0.1s ease-in;
}
