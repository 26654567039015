.custom-student-price-dialog {
  --dialog-background: white;
  background-color: var(--dialog-background);
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 750px;
  min-height: 25vh;
  max-height: 85vh;
  padding: 25px;
  z-index: 50;
  overflow-y: auto;
}

.student-subject-item {
  font-weight: 500;
  font-size: small;
  color: gray;
}

.student-subject-title {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* @media (min-width: 960px) {
    .subject-item {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
    }
  } */
