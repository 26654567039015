.error-dialog-content {
  background-color: #87ceeb !important;
  height: 50vh;
}

.error-dialog-text {
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
}
