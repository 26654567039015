@import "react-big-calendar/lib/sass/styles";

.rbc-time-view {
  border: none;
}

.rbc-time-gutter,
.rbc-time-header-gutter {
  position: sticky;
  left: 0;
  z-index: 0;
  background-color: transparent !important;
}

.rbc-time-gutter > .rbc-timeslot-group > .rbc-time-slot {
  margin-top: -0.5rem;
}

.rbc-allday-cell {
  display: none;
}

.rbc-header {
  border: none !important;
  text-align: left;
  padding: 1rem;
  padding-right: 0;
}

.rbc-time-header-content {
  border: none;
}

.rbc-timeslot-group {
  border: none !important;
  border-left: 0px solid #ddd !important;
  min-height: 8rem;
}

.rbc-time-content {
  gap: 1rem;
  border-left: 0px solid #ddd !important;
  border-top: 0px solid #ddd;
  overflow-x: hidden;
}

.rbc-day-slot,
.rbc-time-column {
  background-color: white;
  border: none;
  border-radius: 10px;
  margin-right: 0;
}

.rbc-events-container {
  border: 0px solid #ddd;
  margin-right: 0 !important;
}

.rbc-header {
  background-color: transparent;
}

.rbc-header.rbc-today {
  background-color: transparent !important;
}

.rbc-today .calendar--date-number {
  background-color: rgba(var(--purple-rgb));
  color: white;
  border-radius: 50%;
  padding: 0.25rem;
}

.rbc-time-gutter .rbc-timeslot-group:nth-child(1) {
  margin-top: 0.1rem !important;
}

.rbc-timeslot-group:nth-child(1) .rbc-time-slot:nth-child(1) {
  border-top: 0px solid #ddd !important;
}

.rbc-day-slot .rbc-time-slot:nth-child(1) {
  border-top: 1px solid #ddd;
}

.rbc-day-slot .rbc-time-slot:nth-child(2) {
  border-top: 1px solid #eee;
}

.rbc-current-time-indicator {
  background-color: red;
  left: -100vw;
  width: 200vw;
}

.rbc-label {
  font-size: 12px;
}

.rbc-event-label {
  display: none;
}

.rbc-event-content {
  background-color: white;
}

.rbc-event {
  background-color: transparent;
  border: 0px solid #ddd !important;
  color: black;
  padding: 0;
}

.rbc-event:hover {
  z-index: 5;
}

.rbc-background-event {
  opacity: 1 !important;
  width: 100% !important;
  z-index: 3;
  left: 50%;
  transform: translateX(0%);
  height: calc(100% - 0.5rem);
}

.rbc-calendar {
  height: 75vh;
}

.availability-sidebar-wrapper {
  .rbc-event {
    width: 100% !important;
    left: 0 !important;
    bottom: 0 !important;
    border-radius: 0px;
  }
  .rbc-calendar {
    height: 50vh;
  }

  .rbc-label {
    font-size: 1rem;
    font-weight: 700;
  }

  .rbc-event-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1rem;
    padding-left: 0.5rem;
    color: white;
    opacity: 1;
    font-weight: 500;
    white-space: pre-line;
    line-height: 1.5;
  }

  .session-event .rbc-event-content {
    border-radius: 10px;
  }

  .event-red .rbc-event-content {
    background: #ff4141;
  }

  .event-yellow .rbc-event-content {
    background: #ffca41;
  }

  .event-green .rbc-event-content {
    background: #00904e;
  }

  .event-sheer .rbc-event-content {
    opacity: 0.2;
  }
}
