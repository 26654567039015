.tutors--header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.tutors--header-left {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.subject-select {
  min-width: 220px;
}

.tutors--header-right button {
  margin-left: 0.5rem;
}

@media (max-width: 600px) {
  .tutors--header-left {
    flex-direction: column;
  }
}

.tutors--grid {
  display: grid;
  grid-template-columns: 100%;
  gap: 0.5rem;
}

@media (min-width: 769px) {
  .tutors--grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }
}

@media (min-width: 960px) {
  .tutors--grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1200px) {
  .tutors--grid {
    grid-template-columns: repeat(5, 1fr);
  }
}

.tutor-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 min(100%, 275px);
  background-color: var(--white);
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
}

.tutor-card--top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tutor-card--image {
  width: 100%;
  height: 200px;
  margin-bottom: 0.25rem;
  overflow: hidden;
  border-radius: 4px;
}

.tutor-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.tutor-initials {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.tutor-initials--text {
  font-size: 32px;
  font-weight: 600;
  color: #ccc;
}

.tutor--name {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}

.tutor--bio {
  margin: 0.5rem 0 0;
  font-size: 14px;
  line-height: 1.4;
}

.tutor-card--footer {
  margin-top: 1rem;
}
