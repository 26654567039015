@keyframes overlayShow {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }

  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.dialog-overlay {
  background: rgba(0 0 0 / 0.5);
  position: fixed;
  inset: 0;
  z-index: 50;
}

.dialog-content {
  --dialog-background: white;
  background-color: var(--dialog-background);
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 450px;
  min-height: 10vh;
  max-height: 85vh;
  padding: 25px;
  z-index: 50;
  overflow-y: auto;
}

.dialog-content--left {
  padding-top: 0px;
  padding-bottom: 0px;
}

.dialog-content:focus {
  outline: none;
}

.dialog-close {
  all: unset;
  font-family: inherit;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--black);
  position: absolute;
  top: 15px;
  right: 10px;
  z-index: 1;
  transition: 0.15s ease;
  cursor: pointer;
}

.dialog-close--left {
  position: relative;
  top: 15px;
  z-index: 1;
}

.dialog-close:hover {
  background-color: rgba(var(--green-rgb), 0.25);
}

.dialog--actions {
  margin-top: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

@media (prefers-reduced-motion: no-preference) {
  .dialog-content {
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  .dialog-overlay {
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }
}

@media (min-width: 960px) {
  .dialog--actions {
    flex-direction: row;
  }

  .dialog--actions .button {
    width: 100%;
  }
}
