.manage-tasks-dialog {
  --dialog-background: white;
  background-color: var(--dialog-background);
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.9);
  transform-origin: center center;
  width: 90vw;
  max-width: 1350px;
  min-height: 100vh;
  max-height: 100vh;
  padding: 25px;
  padding-bottom: 0;
  z-index: 50;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

/* Bottom Navbar pinned to bottom */
.bottom-task-navbar {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin: 0 -25px;
  margin-top: auto;
  margin-bottom: 0;
}

.create-task-dialog {
  --dialog-background: white;
  background-color: var(--dialog-background);
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.9);
  width: 95vw;
  max-height: 85vh;
  max-width: 1350px;
  padding: 25px;
  z-index: 50;
  overflow-y: auto;
}

.delete-task-dialog {
  background-color: white;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  padding: 25px;
  z-index: 100;
}

.delete-task-content {
  display: flex;
  flex-direction: column;
}

.task-filters {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
  flex-wrap: wrap;
}

.task-filters .filter-group {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.task-filters .filter-group label {
  font-size: 12px;
  color: #6c757d;
}

.task-filters .search-group {
  flex: 1;
  min-width: 200px;
}

.task-filters .filters-row {
  display: flex;
  gap: 16px;
  width: 100%;
}

.task-filters .filters-row > * {
  flex: 1;
}

.task-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.task-item {
  border: 1px solid #ddd;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}

.task-header {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 10px 12px;
  background-color: #f8f9fa;
}

.bottom-actions {
  position: absolute;
  bottom: 8px;
  right: 8px;
  display: flex;
  gap: 6px;
  align-items: center;
}

.action-button {
  width: 24px;
  height: 24px;
  border: 1px solid #ddd;
  background-color: white;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  padding: 0;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.action-button:hover {
  background-color: #f8f9fa;
  border-color: #ccc;
}

.action-button.save-button {
  color: #28a745;
  border-color: #28a745;
}

.action-button.save-button:hover {
  background-color: #28a745;
  color: white;
}

.action-button.cancel-button {
  color: #dc3545;
  border-color: #dc3545;
}

.action-button.cancel-button:hover {
  background-color: #dc3545;
  color: white;
}

.expand-button {
  width: 24px;
  height: 24px;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  padding: 0;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.expand-button:hover {
  background-color: #f8f9fa;
  border-color: #ccc;
}

.task-preview {
  flex: 1;
}

.task-preview h3 {
  font-size: 15px;
  color: #333;
  font-weight: 500;
}

.task-preview p {
  margin: 0;
  font-size: 14px;
  color: #666;
  line-height: 1.4;
}

.task-actions {
  display: flex;
  gap: 6px;
  align-items: center;
}

.task-actions button {
  width: 28px;
  height: 28px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.task-actions button:hover {
  background-color: #eee;
}

.task-actions select {
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 13px;
  background-color: white;
  height: 28px;
}

.task-details {
  padding: 12px;
  border-top: 1px solid #ddd;
  background-color: white;
}

.user-list {
  display: flex;
  flex-direction: column;
}

.user-list h4 {
  margin: 0;
  font-size: 13px;
  color: #666;
  margin-bottom: 8px;
}

.user-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.user-group {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.user-group h5 {
  margin: 0;
  font-size: 12px;
  color: #666;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.user-group div {
  font-size: 13px;
  color: #333;
}

.task-edit-form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.task-edit-form input,
.task-edit-form textarea {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.task-edit-form textarea {
  min-height: 100px;
  resize: vertical;
}

.edit-actions {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.edit-actions button {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.edit-actions button:first-child {
  background-color: #007bff;
  color: white;
}

.edit-actions button:last-child {
  background-color: #6c757d;
  color: white;
}

.edit-actions button:hover {
  opacity: 0.9;
}

/* IF YOU REMOVE THE STYLES BELOW THIS POINT, IT WILL ADD A REALLY WEIRD 
BLUR TO THE EDIT AND CREATE TASK DIALOGS */
.name-input-wrapper {
  position: relative;
  isolation: isolate;
  flex: 1;
}

.name-input-wrapper input {
  position: relative;
}

.name-input-wrapper input:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: #ddd !important;
}

.content-input-wrapper {
  position: relative;
  isolation: isolate;
}

.content-input-wrapper textarea {
  position: relative;
}

.content-input-wrapper textarea:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: #ddd !important;
}
