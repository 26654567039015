.delete-note--dialog-header {
  margin-bottom: 0.75rem;
}

.delete-note--title {
  font-weight: bold;
  font-size: 1.15em;
}

.delete-note--dialog-header p {
  margin-top: 0.5rem;
  font-size: 14px;
  line-height: 1.4em;
}
