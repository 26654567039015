.profile_body {
  padding-bottom: 180px;
}

/* Navbar Container */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  background: #fff;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: center; /* Center everything horizontally */
  align-items: center;
  padding: 0 1rem;
  height: 90px; /* Increased height */
}

/* Logo on the left corner */
.nav-left {
  position: absolute;
  left: 1rem;
  display: flex;
  align-items: center;
}

/* Toggle container truly centered in the navbar */
.nav-center {
  display: flex;
  justify-content: center;
}

/* Organization Logo */
.org-logo {
  height: 60px;
  width: auto;
  object-fit: contain;
}

/* Bottom Navbar pinned to bottom */
.bottom-navbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  z-index: 900;
}

/* Contact and Address sections */
.bottom-left,
.bottom-right {
  font-size: 14px;
  line-height: 1.4;
}

/* Toggle Container */
.web-page-toggle-container {
  display: inline-flex;
  border-radius: 9999px;
  overflow: hidden;
  align-items: center;
  justify-content: flex-start;
  border-width: 2px; /* slightly thicker border */
  border-style: solid;
  transform: scale(0.85); /* Make toggle slightly smaller */
}

/* Toggle Buttons (Bigger) */
.web-page-toggle-button {
  background: transparent;
  color: #000;
  padding: 0.6rem 1.2rem; /* Reduced padding */
  border: none;
  cursor: pointer;
  font-size: 0.9rem; /* Slightly smaller font */
  outline: none;
  transition: background-color 0.3s, color 0.3s;
}

/* Active state changes */
.web-page-toggle-button.active {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
}

/* Hover state for non-active buttons */
.web-page-toggle-button:not(.active):hover {
  background: rgba(255, 255, 255, 0.3);
}

/* Greeting & Bio */
.org-greeting {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
}

.org-bio {
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 0.5rem;
}

/* Light Grey dividers between sections */
.section-divider {
  margin: 2rem auto;
  width: 85%;
  border: none;
  border-top: 1px solid #ccc;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    height: auto;
  }

  .nav-left {
    position: static;
    margin-bottom: 0.5rem;
  }

  .nav-center {
    margin-bottom: 0.5rem;
  }

  .org-greeting {
    margin-top: 150px; /* extra space on smaller screens */
  }
}

.booking-input {
  width: 100%;
  line-height: 1.75rem;
  background-color: white;
  border: 1px solid #d1d5db;
  border-style: solid;
  border-radius: 0.375rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.booking-input:hover {
  border-color: #9ca3af;
}

.booking-input::placeholder {
  color: #9ca3af;
}

.booking-input:focus {
  border-color: #6366f1;
  outline: none;
  box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.2);
}

.booking-input {
  font-size: 0.875rem;
  line-height: 1.5rem;
}

/* For the 6-digit verification code boxes */
.code-box {
  width: 3rem;
  height: 3rem;
  text-align: center;
  font-size: 1.25rem;
  border: 1px solid #ccc; /* softer gray border */
  border-radius: 6px; /* round corners */
  outline: none;
}
.code-box:focus {
  border-color: #999;
}

.about-tutor-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (min-width: 768px) {
  .about-tutor-container {
    flex-direction: row;
  }
}

.single-tutor-card {
  flex: 0 0 300px;
}

.subjects-grid-wrapper {
  flex: 1;
}

.tutor-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 min(100%, 275px);
  background-color: var(--white);
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
}

.tutor-card--top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tutor-card--image {
  width: 100%;
  height: 200px;
  margin-bottom: 0.25rem;
  overflow: hidden;
  border-radius: 4px;
}

.tutor-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.tutor-initials {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.tutor-initials--text {
  font-size: 32px;
  font-weight: 600;
  color: #ccc;
}

.tutor--name {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}

.tutor--bio {
  margin: 0.5rem 0 0;
  font-size: 14px;
  line-height: 1.4;
}

.tutor-card--footer {
  margin-top: 1rem;
}
