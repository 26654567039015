@media (max-width: 410px) {
  .message-invite-buttons {
    grid-template-rows: 1fr 1fr !important;
    grid-template-columns: 1fr !important;
    gap: 0.5rem !important;
  }

  .invite-button {
    margin-left: 0 !important;
  }
}
