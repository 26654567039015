/* index.css */

/* The main container for our query builder. 
   Takes up full width, has a purple border, rounded corners, etc. */
.query--container {
  margin-top: 1rem;
  border-radius: 0.75rem;
  padding: 0.5rem;
  width: 100%;
  position: relative;
  overflow-x: auto;
}

/* Condition Group root container from previous code */
.condition-group {
  border: 2px solid #e5eaff;
  border-radius: 0.5rem;
  padding: 20px;
  width: max-content;
  min-width: max-content;
  position: relative;
}

/* The center column in ConditionGroup. We allow some spacing inside for the operator/children. */
.condition-group--center {
  flex: 1;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: max-content;
  min-width: max-content;
  position: relative;
}

/* Group controls container */
.condition-group-controls {
  display: flex;
  flex-wrap: nowrap;
  gap: 12px;
  margin-bottom: 8px;
  position: relative;
  width: max-content;
  min-width: max-content;
}

@media (max-width: 768px) {
  .condition-group,
  .condition-group--center,
  .condition-group-controls {
    width: 100%;
    min-width: 100%;
    flex-wrap: wrap;
  }

  .condition-group-controls {
    flex-direction: column;
  }

  .condition-group-controls > * {
    width: 100%;
  }
}

/* A row for each condition or nested group inside the center column */
.condition-or-group-row {
  margin-left: 8px;
  width: max-content;
  min-width: max-content;
}

@media (max-width: 768px) {
  .condition-or-group-row {
    margin-left: 0;
    width: 100%;
    min-width: 100%;
  }
}

/* 3-column layout for each Condition row */
.condition-row {
  background-color: #fafaff;
  border: 2px solid #dfd8fc;
  border-radius: 0.5rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
  padding: 8px;
  display: flex;
  flex-direction: row;
  gap: 30px;
  flex-wrap: nowrap;
  width: max-content;
  min-width: max-content;
  align-items: center;
}

@media (max-width: 768px) {
  .condition-row {
    flex-direction: column;
    width: 100%;
    min-width: 100%;
  }

  .condition-row > * {
    width: 100% !important;
    min-width: 100% !important;
  }
}

/* Container for the condition row and its remove button */
.query--container .flex.flex-row {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  width: max-content;
  min-width: max-content;
}

@media (max-width: 768px) {
  .query--container .flex.flex-row {
    flex-direction: column;
    width: 100%;
    min-width: 100%;
  }

  .query--container .flex.flex-row button {
    width: 100%;
  }
}

/* Input field styles to reduce height and spacing */
.condition-row input.input,
.condition-row .select__control {
  min-height: 38px !important;
  height: 38px;
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
  min-width: 175px;
  flex-shrink: 0;
}

.condition-row .select__value-container {
  padding-top: 0;
  padding-bottom: 0;
}

.condition-row .select__input-container {
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
}

/* Results section styling */
.results-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .results-container {
    flex-direction: column;
  }

  .results-container > * {
    width: 100%;
  }
}

/* Tag styling for results */
.results-tag {
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
}

@media (max-width: 768px) {
  .results-tag {
    width: 100%;
    margin-right: 0;
  }
}

/* Select dropdown container styles */
.select__menu-portal {
  z-index: 9999;
}

.select__menu {
  z-index: 9999;
}

/* Ensure select control is above other elements */
.select__control {
  z-index: 2;
  position: relative;
}
