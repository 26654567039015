.credit-container {
  min-width: 350px;
}

.balance-styling {
  font-size: 30px;
  font-family: "Montserrat", sans-serif;
  width: 100%;
}

.pay-per-session-styling {
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  margin-top: 10px;
  width: 100%;
}

.edit-button-styling {
  color: white;
  background-color: #3db7e3;
}

.balance-button-styling {
  color: white;
  background-color: plum;
}

.toggle-container {
  display: inline-flex;
  border: 1px solid #9c27b0;
  border-radius: 9999px;
  overflow: hidden;
  align-items: center;
  justify-content: flex-start;
  /* transform: scale(0.8); */
  transform-origin: left center;
}

.toggle-button {
  background: #ffffff;
  color: #000000;
  padding: 0.25rem 0.5rem;
  border: none;
  cursor: pointer;
  font-size: 0.75rem;
  outline: none;
  transition: background-color 0.3s, color 0.3s;
}

.toggle-button.active {
  background: #d8bfee;
  color: #ffffff;
}

.toggle-button:not(.active):hover {
  background: #f5f5f5;
}
