.subjects--header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.subjects--header-left {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.subjects--header-right button {
  margin-left: 0.5rem;
}

@media (max-width: 600px) {
  .subjects--header-left {
    flex-direction: column;
  }
}

.subjects--grid {
  display: grid;
  grid-template-columns: 100%;
  gap: 0.5rem;
}

@media (min-width: 769px) {
  .subjects--grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }
}

@media (min-width: 960px) {
  .subjects--grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1200px) {
  .subjects--grid {
    grid-template-columns: repeat(5, 1fr);
  }
}

.subject-card {
  display: flex;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: var(--white);
  overflow: hidden;
}

.subject-card--border {
  width: 12px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.subject-card--content {
  flex: 1;
  padding: 1rem;
}

.subject--name {
  margin: 0;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 1.1rem;
}

.subject--description {
  margin: 0;
  font-size: 14px;
  line-height: 1.4;
}
